/* Navigation Bar */
nav {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
}

nav .container {
  height: 165px;
  padding: 0px 100px;
  background: #23273b;
  border-radius: 0px 0px 100px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .container .nav-left {
  display: flex;
}

nav .container .nav-left .logo {
  height: 165px;
  display: flex;
}

nav .container .nav-left .logo img {
  height: 100%;
}

nav .container .nav-right {
  display: flex;
  align-items: center;
}

nav .container .nav-right .hamburger-menu-close-trigger {
  position: absolute;
  top: 30px;
  right: 30px;
  display: none;
}

nav .container .nav-right ul {
  display: flex;
  margin-right: 50px;
}

nav .container .nav-right ul li {
  margin-right: 30px;
}

nav .container .nav-right ul li:last-child {
  margin-right: 0px;
}

nav .container .nav-right ul li a {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primary-text-color);
  transition: all 0.3s ease;
  cursor: pointer;
}

nav .container .nav-right ul li a:hover {
  color: var(--primary-color);
}

nav .container .nav-right .connect-wallet-btn {
  width: 220px;
  height: 65px;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  border-radius: 18px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.3s ease;
}

nav .container .nav-right .connect-wallet-btn:hover {
  background: transparent;
  color: var(--primary-color);
}

nav .container .hamburger-menu-open-trigger {
  display: none;
}

/* Responsive */
@media only screen and (max-width: 1150px) {
  nav .container .nav-left .logo {
    height: 80px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  nav .container {
    height: 80px;
    padding: 0px 50px;
  }

  nav .container .nav-right {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: #23273b;
    flex-direction: column;
    justify-content: center;
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }

  nav .container .nav-right.active {
    transform: translateX(0%);
  }

  nav .container .nav-right .hamburger-menu-close-trigger {
    display: flex;
  }

  nav .container .nav-right ul {
    flex-direction: column;
    margin-right: 0px;
  }

  nav .container .nav-right ul li {
    text-align: center;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  nav .container .nav-right ul li:last-child {
    margin-bottom: 0px;
  }

  nav .container .nav-right ul li a {
    text-align: center;
  }

  nav .container .nav-right .connect-wallet-btn {
    margin-top: 20px;
  }

  nav .container .hamburger-menu-open-trigger {
    display: flex;
  }
}

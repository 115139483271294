@import "../includes/_imports.css";

.announcements-page {
  width: 100%;
  overflow-x: hidden;
}

/* Top Content Section */
.announcements-page .top-content {
  width: 100%;
  padding: 265px 0px 100px 0px;
  display: flex;
  justify-content: center;
}

.announcements-page .top-content h1 {
  font-size: 128px;
  text-align: center;
  margin-bottom: 50px;
}

.announcements-page .top-content .container .button {
  margin: 50px auto 0px auto;
  width: fit-content;
  display: flex;
  position: relative;
  cursor: pointer;
}

.announcements-page .top-content .container .button .top-part {
  position: absolute;
  top: 0px;
  left: 1px;
  transition: all 0.2s ease;
}

.announcements-page .top-content .container .button:hover .top-part {
  transform: translate(-10px, -10px);
}

.announcements-page .top-content .container .button .top-part p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -55%);
  width: fit-content;
  font-size: 30px;
  font-weight: 600;
}

/* Responsive (Hero Section) */
@media only screen and (max-width: 1150px) {
  .announcements-page .top-content {
    padding: 180px 0px 100px 0px;
  }
}

@media only screen and (max-width: 678px) {
  .announcements-page .top-content .container h1 {
    font-size: 80px;
  }
}

@import "../includes/_imports.css";

.mint-page {
  width: 100%;
  overflow-x: hidden;
}

/* Mint Section */
.mint-page .mint {
  width: 100%;
  padding: 265px 0px 0px 0px;
  display: flex;
  justify-content: center;
}

.mint-page .mint .container {
  display: flex;
  justify-content: center;
}

.mint-page .mint .container .mint-box {
  width: 800px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  box-shadow: 0px 0px 250px rgba(255, 255, 255, 0.2);
  border-radius: 80px;
}

.mint-page .mint .container .mint-box .mint-box-container {
  width: 100%;
  padding: 100px;
}

.mint-page .mint .container .mint-box .mint-box-container .minted-p {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  -webkit-text-stroke: 1px var(--secondary-color);
  color: var(--primary-color);
}

.mint-page .mint .container .mint-box .mint-box-container .nft-info-box {
  width: 100%;
  padding: 20px 70px 0px 70px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mint-page .mint .container .mint-box .mint-box-container .nft-info-box img {
  width: 150px;
}

.mint-page
  .mint
  .container
  .mint-box
  .mint-box-container
  .nft-info-box
  .nft-info-box-right {
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
}

.mint-page
  .mint
  .container
  .mint-box
  .mint-box-container
  .nft-info-box
  .nft-info-box-right
  h4 {
  font-size: 35px;
  font-weight: 900;
  text-align: right;
  text-transform: uppercase;
}

.mint-page .mint .container .mint-box .mint-box-container .quantity-box {
  margin: 20px 0px;
  width: 100%;
  padding: 20px 70px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mint-page
  .mint
  .container
  .mint-box
  .mint-box-container
  .quantity-box
  .quantity-box-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mint-page
  .mint
  .container
  .mint-box
  .mint-box-container
  .quantity-box
  .quantity-box-left
  p {
  margin: 0px 20px;
  font-size: 35px;
  font-weight: 900;
  text-align: right;
  text-transform: uppercase;
}

.mint-page
  .mint
  .container
  .mint-box
  .mint-box-container
  .quantity-box
  .quantity-box-right
  p {
  font-size: 25px;
  font-weight: 900;
  text-align: right;
  text-transform: uppercase;
}

.mint-page .mint .container .mint-box .mint-box-container .total-box {
  width: 100%;
  padding: 20px 70px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mint-page .mint .container .mint-box .mint-box-container .total-box p {
  font-size: 25px;
  font-weight: 900;
  text-align: right;
  text-transform: uppercase;
}

.mint-page .mint .container .mint-box .mint-box-container .mint-btn {
  margin-top: 20px;
  width: 100%;
  height: 76px;
  border: 3px solid var(--primary-color);
  background: var(--primary-color);
  border-radius: 40px;
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.mint-page .mint .container .mint-box .mint-box-container .mint-btn:hover {
  background: transparent;
  color: var(--primary-color);
}

@font-face {
  font-family: "Vicious Hunger";
  src: url("./fonts/vicious-hunger.ttf");
  font-weight: normal;
}

:root {
  /* Colors */
  --primary-color: #48a9e0;

  /* Text Colors */
  --primary-text-color: #ffffff;
  --secondary-text-color: #16798e;

  /* Font Families */
  --primary-font-family: "Open Sans", sans-serif;
  --secondary-font-family: "Vicious Hunger", sans-serif;
}

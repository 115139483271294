* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--primary-font-family);
  color: var(--primary-text-color);
}

::selection {
  background: var(--primary-color);
  color: var(--primary-text-color);
}

h1,
h2 {
  font-family: var(--secondary-font-family);
  font-weight: normal;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

button,
input,
select,
textarea {
  outline: none;
  border: none;
  background: none;
  font-family: var(--primary-font-family);
  color: var(--primary-text-color);
}

button,
select {
  cursor: pointer;
}

img {
  user-select: none;
}

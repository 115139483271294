/* Footer */
footer {
  padding-top: 500px;
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

footer .footer-img-1 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 500px;
}

footer .footer-img-2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 500px;
}

footer .container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .container .social-links {
  display: flex;
  margin-bottom: 20px;
}

footer .container .social-links a {
  transition: all 0.3s ease;
  margin-right: 10px;
}

footer .container .social-links a:last-child {
  margin-right: 0px;
}

footer .container .social-links a:hover {
  transform: translateY(-5px);
}

footer .container .social-links a svg path {
  transition: all 0.3s ease;
}

footer .container .social-links a:hover svg path {
  fill: var(--primary-color);
}

footer .container p {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

footer .container p span {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary-color);
}

footer .container p a {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary-color);
}

/* Responsive */
@media only screen and (max-width: 1150px) {
  footer {
    padding-top: 300px;
  }

  footer .footer-img-1 {
    width: 300px;
  }

  footer .footer-img-2 {
    width: 300px;
  }
}

@media only screen and (max-width: 678px) {
  footer {
    padding-top: 200px;
  }

  footer .footer-img-1 {
    left: -180px;
  }

  footer .footer-img-2 {
    right: -150px;
  }

  footer .container p {
    font-size: 16px;
    margin-bottom: 200px;
  }
}

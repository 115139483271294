@import "../includes/_imports.css";

.landing-page {
	width: 100%;
	overflow-x: hidden;
}

/* Hero Section */
.hero {
	width: 100%;
	padding: 265px 0px 100px 0px;
	display: flex;
	justify-content: center;
	position: relative;
}

.hero .overlay {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0), #23273b);
}

.hero .container {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.hero .container h1 {
	font-size: 128px;
	text-align: center;
	margin-bottom: 20px;
}

.hero .container p {
	font-size: 30px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 40px;
}

.hero .container .button {
	width: fit-content;
	display: flex;
	position: relative;
	cursor: pointer;
	margin-bottom: 50px;
}

.hero .container .button .top-part {
	position: absolute;
	top: 0px;
	left: 1px;
	transition: all 0.2s ease;
}

.hero .container .button:hover .top-part {
	transform: translate(-10px, -10px);
}

.hero .container .button .top-part p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-45%, -55%);
	width: fit-content;
	font-size: 30px;
	font-weight: 600;
}

.hero .container .timer-box {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px 0px;
}

.hero .container .timer-box .timer-box-part {
	display: flex;
}

.hero .container .timer-box .timer-box-part .timer-box-part-box {
	display: flex;
	position: relative;
}

.hero .container .timer-box .timer-box-part .timer-box-part-box p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: var(--secondary-font-family);
	font-size: 110px;
	font-weight: normal;
}

.hero .container .timer-box .dot {
	margin: 0px 20px 0px 30px;
}

.hero .container .timer-box .dot p {
	font-family: var(--secondary-font-family);
	font-size: 80px;
	font-weight: normal;
	margin-bottom: 0px;
}

/* About Section */
.about {
	padding-top: 150px;
	display: flex;
	justify-content: center;
	position: relative;
}

.about .container {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 100px;
}

.about .container .about-left {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.about .container .about-left svg {
	margin-bottom: 20px;
}

.about .container .about-left p {
	font-size: 24px;
	font-weight: 500;
}

.about .about-img-1 {
	position: absolute;
	bottom: 0px;
	right: -100px;
	width: 1000px;
}

/* Goal Section */
.goal {
	padding-top: 150px;
	display: flex;
	justify-content: center;
	position: relative;
}

.goal .container {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 100px;
}

.goal .container .goal-left {
	width: 100%;
	display: flex;
}

.goal .container .goal-left img {
	width: 100%;
}

.goal .container .goal-right {
	display: flex;
	flex-direction: column;
}

.goal .container .goal-right svg {
	margin-bottom: 20px;
}

.goal .container .goal-right p {
	font-size: 24px;
	font-weight: 500;
}

/* Team Section */
.team {
	padding-top: 150px;
	display: flex;
	justify-content: center;
	position: relative;
}

.team .container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.team .container svg {
	margin-bottom: 80px;
}

.team .container .team-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 80px;
}

.team .container .team-wrapper .team-member {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.team .container .team-wrapper .team-member:nth-child(5) {
	max-width: 660px;
	margin: 0 auto;
	grid-column: 1 / span 2;
}

.team .container .team-wrapper .team-member img {
	width: 450px;
	margin-bottom: 30px;
}

.team .container .team-wrapper .team-member p {
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	color: var(--primary-text-color);
}

.team .container .team-wrapper .team-member p span {
	font-weight: 700;
}

/* Roadmap Section */
.roadmap {
	padding-top: 150px;
	display: flex;
	justify-content: center;
	position: relative;
}

.roadmap .container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.roadmap .container svg {
	margin-bottom: 80px;
}

.roadmap .container .roadmap-rows {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
}

.roadmap .container .roadmap-rows .roadmap-row {
	width: 100%;
}

.roadmap .container .roadmap-rows .roadmap-row .roadmap-shape {
	width: 600px;
	position: relative;
	z-index: 2;
}

.roadmap .container .roadmap-rows .roadmap-row-right-type {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.roadmap .container .roadmap-rows .roadmap-row-right-type .roadmap-shape {
	margin-right: -50px;
}

.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box {
	width: 440px;
	padding: 30px 70px 30px 30px;
	background: #2d3145;
	position: relative;
}

.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box h4 {
	font-size: 35px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 5px;
}

.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box p {
	font-size: 20px;
	font-weight: 500;
	text-align: center;
}

.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box img {
	position: absolute;
	top: 50%;
	right: -150px;
	transform: translateY(-50%);
}

.roadmap .container .roadmap-rows .roadmap-row-left-type {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.roadmap .container .roadmap-rows .roadmap-row-left-type .roadmap-shape {
	margin-left: -50px;
}

.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box {
	width: 440px;
	padding: 30px 30px 30px 70px;
	background: #2d3145;
	position: relative;
}

.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box h4 {
	font-size: 35px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 5px;
}

.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box p {
	font-size: 20px;
	font-weight: 500;
	text-align: center;
}

.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box img {
	position: absolute;
	top: 50%;
	left: -150px;
	transform: translateY(-50%);
}

/* Rarity Tiers Section */
.rarity-tiers {
	padding-top: 150px;
	display: flex;
	justify-content: center;
	position: relative;
}

.rarity-tiers .container {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 100px;
}

.rarity-tiers .container .rarity-tiers-left {
	display: flex;
	flex-direction: column;
}

.rarity-tiers .container .rarity-tiers-left svg {
	margin-bottom: 20px;
}

.rarity-tiers .container .rarity-tiers-left p {
	font-size: 24px;
	font-weight: 500;
}

.rarity-tiers .container .rarity-tiers-right {
	width: 100%;
	display: flex;
}

.rarity-tiers .container .rarity-tiers-right img {
	width: 100%;
}

/* Benefits Section */
.benefits {
	padding-top: 150px;
	display: flex;
	justify-content: center;
	position: relative;
}

.benefits .container {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 100px;
}

.benefits .container .benefits-left {
	width: 100%;
	display: flex;
}

.benefits .container .benefits-left img {
	width: 100%;
}

.benefits .container .benefits-right svg {
	margin-bottom: 20px;
}

.benefits .container .benefits-right p {
	font-size: 24px;
	font-weight: 500;
}

/* Drop Schedule Section */
.drop-schedule {
	padding-top: 150px;
	display: flex;
	justify-content: center;
	position: relative;
}

.drop-schedule .container {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 100px;
}

.drop-schedule .container .drop-schedule-left {
	display: flex;
	flex-direction: column;
}

.drop-schedule .container .drop-schedule-left svg {
	margin-bottom: 20px;
}

.drop-schedule .container .drop-schedule-left p {
	font-size: 24px;
	font-weight: 500;
}

.drop-schedule .container .drop-schedule-right {
	width: 100%;
	display: flex;
}

.drop-schedule .container .drop-schedule-right img {
	width: 100%;
}

/* FAQ Section */
.faq {
	padding-top: 100px;
	display: flex;
	justify-content: center;
	position: relative;
}

.faq .container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.faq .container svg {
	margin-bottom: 80px;
}

.faq .container .faqs-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
}

.faq .container .faqs-wrapper .question-answer {
	width: 100%;
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	overflow: hidden;
	transition: 0.3s ease-in-out;
}

.faq .container .faqs-wrapper .question-answer:last-child {
	padding-bottom: 0px;
	border-bottom: none;
}

.faq .container .faqs-wrapper .question-answer .question {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.faq .container .faqs-wrapper .question-answer .question p {
	font-size: 22px;
	font-weight: 700;
	margin-right: 20px;
}

.faq .container .faqs-wrapper .question-answer .question svg {
	min-width: 23px;
	max-width: 23px;
	margin-bottom: 0px;
	transition: all 0.3s ease;
}

.faq .container .faqs-wrapper .question-answer.active .question svg {
	transform: rotate(180deg);
}

.faq .container .faqs-wrapper .question-answer .answer {
	padding-top: 20px;
	font-size: 20px;
	font-weight: 500;
	/* display: none; */
}

.faq .container .faqs-wrapper .question-answer.active .answer {
	display: block;
}

/* Community Section */
.community {
	padding-top: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.community .container {
	padding: 70px 200px;
	background: var(--primary-color);
	border-radius: 250px 0px 250px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.community .container .community-img-1 {
	position: absolute;
	bottom: -50px;
	left: -100px;
	width: 500px;
}

.community .container h2 {
	font-size: 100px;
	text-align: center;
	margin-bottom: 20px;
}

.community .container h2 span {
	color: transparent;
	-webkit-text-stroke: 1px #ffffff;
}

.community .container p {
	font-size: 22px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 40px;
}

.community .container button {
	display: flex;
	transition: all 0.3s ease;
}

.community .container button:hover {
	transform: scale(0.95);
}

/* Responsive */

/* Responsive (Hero Section) */
@media only screen and (max-width: 1150px) {
	.hero {
		padding: 180px 0px 100px 0px;
	}
}

@media only screen and (max-width: 678px) {
	.hero .container h1 {
		font-size: 80px;
	}

	.hero .container p {
		font-size: 20px;
	}

	.hero .container .timer-box .dot {
		display: none;
	}
}

/* Responsive (About Section) */
@media only screen and (max-width: 1320px) {
	.about .container {
		grid-template-columns: 1.5fr 1fr;
	}

	.about .about-img-1 {
		width: 800px;
	}
}

@media only screen and (max-width: 950px) {
	.about {
		flex-direction: column;
	}

	.about .container {
		grid-template-columns: 1fr;
	}

	.about .container .about-left {
		align-items: center;
	}

	.about .container .about-left p {
		text-align: center;
	}

	.about .about-img-1 {
		width: 100%;
		position: static;
	}
}

@media only screen and (max-width: 678px) {
	.about {
		padding-top: 80px;
	}

	.about .container .about-left svg {
		width: 100% !important;
		height: auto;
	}

	.about .container .about-left p {
		font-size: 20px;
	}
}

/* Responsive (Goal Section) */
@media only screen and (max-width: 1150px) {
	.goal .container {
		grid-template-columns: 1fr;
	}

	.goal .container .goal-right {
		align-items: center;
	}

	.goal .container .goal-right p {
		text-align: center;
	}
}

@media only screen and (max-width: 678px) {
	.goal {
		padding-top: 80px;
	}

	.goal .container {
		grid-gap: 50px;
	}

	.goal .container .goal-left {
		order: 2;
	}

	.goal .container .goal-right svg {
		width: 100% !important;
		height: auto;
	}

	.goal .container .goal-right p {
		text-align: center;
		font-size: 20px;
	}
}

/* Responsive (Team Section) */
@media only screen and (max-width: 1320px) {
	.team .container .team-wrapper .team-member img {
		width: 300px;
	}
}

@media only screen and (max-width: 950px) {
	.team .container .team-wrapper {
		grid-gap: 50px;
	}
}

@media only screen and (max-width: 678px) {
	.team {
		padding-top: 80px;
	}

	.team .container svg {
		width: 100% !important;
		height: auto;
		margin-bottom: 40px;
	}

	.team .container .team-wrapper {
		grid-template-columns: 1fr;
	}

	.team .container .team-wrapper .team-member:nth-child(5) {
		grid-column: -1/1;
	}

	.team .container .team-wrapper .team-member img {
		width: 250px;
		margin-bottom: 20px;
	}

	.team .container .team-wrapper .team-member p {
		font-size: 20px;
	}
}

/* Responsive (Roadmap Section) */
@media only screen and (max-width: 1024px) {
	.roadmap .container .roadmap-rows {
		grid-gap: 50px;
	}

	.roadmap .container .roadmap-rows .roadmap-row {
		flex-direction: column;
		align-items: center;
	}

	.roadmap .container .roadmap-rows .roadmap-row .content-box {
		order: 2;
	}
}

@media only screen and (max-width: 678px) {
	.roadmap {
		padding-top: 80px;
	}

	.roadmap .container svg {
		width: 100% !important;
		height: auto;
	}

	.roadmap .container .roadmap-rows .roadmap-row .roadmap-shape {
		width: 100% !important;
		margin-right: 0px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box img {
		width: 200px;
		right: -120px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box img {
		width: 200px;
		left: -120px;
	}

	.roadmap .container .roadmap-rows .roadmap-row .content-box {
		width: 280px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box {
		padding: 20px 50px 20px 30px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box {
		padding: 20px 30px 20px 50px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box h4 {
		font-size: 25px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-right-type .content-box p {
		font-size: 14px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box h4 {
		font-size: 25px;
	}

	.roadmap .container .roadmap-rows .roadmap-row-left-type .content-box p {
		font-size: 14px;
	}
}

/* Responsive (Rarity Tiers Section) */
@media only screen and (max-width: 1150px) {
	.rarity-tiers .container {
		grid-template-columns: 1fr;
	}

	.rarity-tiers .container .rarity-tiers-left {
		align-items: center;
	}

	.rarity-tiers .container .rarity-tiers-left p {
		text-align: center;
	}
}

@media only screen and (max-width: 678px) {
	.rarity-tiers {
		padding-top: 80px;
	}

	.rarity-tiers .container {
		grid-gap: 50px;
	}

	.rarity-tiers .container .rarity-tiers-right {
		order: 2;
	}

	.rarity-tiers .container .rarity-tiers-left svg {
		width: 100% !important;
		height: auto;
	}

	.rarity-tiers .container .rarity-tiers-left p {
		text-align: center;
		font-size: 20px;
	}
}

/* Responsive (Benefits Section) */
@media only screen and (max-width: 1150px) {
	.benefits .container {
		grid-template-columns: 1fr;
	}

	.benefits .container .benefits-right {
		align-items: center;
	}

	.benefits .container .benefits-right p {
		text-align: center;
	}
}

@media only screen and (max-width: 678px) {
	.benefits {
		padding-top: 80px;
	}

	.benefits .container {
		grid-gap: 50px;
	}

	.benefits .container .benefits-left {
		order: 2;
	}

	.benefits .container .benefits-right svg {
		width: 100% !important;
		height: auto;
	}

	.benefits .container .benefits-right p {
		text-align: center;
		font-size: 20px;
	}
}

/* Responsive (Drop Schedule Section) */
@media only screen and (max-width: 1150px) {
	.drop-schedule .container {
		grid-template-columns: 1fr;
	}

	.drop-schedule .container .drop-schedule-left {
		align-items: center;
	}

	.drop-schedule .container .drop-schedule-left p {
		text-align: center;
	}
}

@media only screen and (max-width: 678px) {
	.drop-schedule {
		padding-top: 80px;
	}

	.drop-schedule .container {
		grid-gap: 50px;
	}

	.drop-schedule .container .drop-schedule-right {
		order: 2;
	}

	.drop-schedule .container .drop-schedule-left svg {
		width: 100% !important;
		height: auto;
	}

	.drop-schedule .container .drop-schedule-left p {
		text-align: center;
		font-size: 20px;
	}
}

/* Responsive (FAQ Section) */
@media only screen and (max-width: 800px) {
	.faq .container svg {
		width: 100% !important;
		height: auto;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 678px) {
	.faq {
		padding-top: 80px;
	}

	.faq .container .faqs-wrapper .question-answer .question p {
		font-size: 18px;
	}

	.faq .container .faqs-wrapper .question-answer .answer p {
		font-size: 16px;
	}
}

/* Responsive (Community Section) */
@media only screen and (max-width: 1024px) {
	.community .container {
		padding: 70px 100px;
	}

	.community .container .community-img-1 {
		width: 300px;
	}
}

@media only screen and (max-width: 678px) {
	.community .container {
		padding: 50px;
		border-radius: 100px 0px 100px 0px;
	}

	.community .container h2 {
		font-size: 70px;
	}

	.community .container p {
		font-size: 20px;
	}

	.community .container .community-img-1 {
		width: 200px;
		left: -50px;
	}
}
